<template>
  <ModalMainNew
    show-close-mobile
    modal-width-mode="4xl"
    secondary-background-color
    @close="onClose"
  >
    <div class="pb-8 text-center">
      <h2 class="text-xl font-bold mb-2 uppercase">
        How To Play
      </h2>
      <div class="flex flex-wrap justify-center gap-8 mb-8">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="w-[260px]"
        >
          <img
            :src="item.image"
            :alt="item.alt"
            class="w-[260px] h-[180px] object-cover"
          >
          <p class="text-gray-400 lg:text-left text-sm leading-tight mt-4">
            {{ item.description }}
          </p>
        </div>
      </div>
      <ButtonButton
        theme="primary"
        class="uppercase"
        size="sm"
        @click.prevent.stop="onClose"
      >
        Let's Go
      </ButtonButton>
    </div>
  </ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

import priceMovement from '~/assets/img/trading/price-movement.png';
import betSelector from '~/assets/img/trading/bet-selector.png';
import leverageSlider from '~/assets/img/trading/leverage-slider.png';
import activeBets from '~/assets/img/trading/active-bets.png';
import cashOut from '~/assets/img/trading/cash-out.png';
import leverageLimit from '~/assets/img/trading/leverage-limit.png';

const uiStore = useUiStore();

const {
  showTradingHowModal,
  tradingHowModalData,
} = storeToRefs(uiStore);

const {
  $toCurrencyString,
} = useNuxtApp();

// TODO: Move to the CMS when we extend to support Feed asset types.
const algorithmicItems = ref([
  {
    image: priceMovement,
    alt: 'Price Movement',
    description: `${tradingHowModalData.value.name} is a trading game where you bet on future price movements. At the end of each round all open bets are closed and the price resets to ${$toCurrencyString(tradingHowModalData.value.startPrice, 'USD', 0, false)}.`,
  },
  {
    image: betSelector,
    alt: 'Up/Down Bet Selector',
    description: 'Bet on whether you think the price will go up or down. As the price moves in your favour, your profit will increase.',
  },
  {
    image: leverageSlider,
    alt: 'Leverage Slider',
    description: 'Select a multiplier to scale your bet. A higher multiplier increases your profit potential, but also increases your bust risk.',
  },
  {
    image: activeBets,
    alt: 'Active Bets',
    description: 'Keep track of the performance of all your open bets on the active bets tab.',
  },
  {
    image: cashOut,
    alt: 'Cash Out',
    description: 'Want to take profits, or cap losses? You can cash out at anytime.',
  },
  {
    image: leverageLimit,
    alt: 'Leverage Limit',
    description: `Unlimited upside, limited downside. You can never lose more than your original bet size, even at ${tradingHowModalData.value.leverageLimit}x.`,
  },
]);

const items = ref(tradingHowModalData.value.type === 'Algorithmic' ? algorithmicItems.value : []);

function onClose() {
  showTradingHowModal.value = false;
  tradingHowModalData.value = null;
}
</script>
